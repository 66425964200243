import React, { useState } from "react";
import Loading from "../../../../../components/Loading";
import {
  Container,
  ElementWrap,
  InlineElements,
  SubmitButton,
  PInput,
  PTextArea,
} from "../styled";
import { defaultFormValues } from "../../../../Franchise/FranchiseForm/_form";
import useForm from "../../../../../hooks/useForm";
import FormConsultation from "../../../../Forms/Consultation";

const SmallForm = ({ submitText }) => {

  return (
    <>
      <Container>
        <FormConsultation />
      </Container>
    </>
  );
};

export default SmallForm;
