import { Col, Row } from "antd";
import Image from "next/image";
import Typography from "../../../components/Typography";
import ScheduleForm from "../../CabinetsPage/Schedule/ScheduleForm";
import { Container, Content, FormContainer } from "./styled";

export default function GetStarted() {
  return (
    <Container role="form" aria-label="Submit info to schedule a design appointment">
      <Row gutter={32}>
        <Col xs={24} md={15}>
          <Image
            src="/images/closets-home/get-started-form.jpg"
            alt="schedule appointment"
            layout="responsive"
            width="1146"
            height="765"
          />
        </Col>
        <Col xs={24} md={7}>
          <Content>
            <span className="title-info" >
              <Typography
                textAlign="center"
                fontSize="1.5rem"
                fontWeight="bold"
                margin={[0]}
              >
                Ready to get started?
              </Typography>
              <Typography textAlign="center">
                A professional designer will contact you for a FREE
                consultation.
              </Typography>
            </span>
            <FormContainer>
              <ScheduleForm submitText="GET STARTED" />
            </FormContainer>
            <span style={{ left: "0px", position: "relative" }}>
              <Typography
                textAlign="center"
                fontWeight="bold"
                margin={[16, 0, 0, 0]}
              >
                Have immediate questions? Call us!
              </Typography>
              <Typography textAlign="center" fontSize="1.7rem" fontWeight="bold">
                <a style={{ color: "black" }} href="tel:801-572-7712">801-572-7712</a>
              </Typography>
            </span>
          </Content>
        </Col>
      </Row>
    </Container>
  );
}
