export const defaultFormValues = {
  first_name: "",
  last_name: "",
  jobTitle: "",
  capitalAvailableInvest: "",
  email: "",
  phone: "",
  city: "",
  state: "",
  comments: "",
};
