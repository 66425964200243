import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import pNotification from "../components/PNotification";
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';

import { sendContactRequestAction } from "../redux/actions/send-contact.actions";
import { eventFormSubmission } from "../services/analytics/ga4CustomEvents";
import { validateEmail } from "../utils/emailValidation";

const useForm = ({ defaultFormValues }) => {

  const dispatch = useDispatch();
  const { loading } = useSelector(({ sentContactData }) => sentContactData);
  const [formValues, setFormValues] = useState(defaultFormValues);
  const [emailErr, setEmailErr] = useState(false);
  const { isLoading, error, data, getData } = useVisitorData();

  const handleChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setFormValues({
      ...formValues,
      [key]: value,
    });
  };

  const sendPixelInfo = async (data) => {
    const response = await fetch("/api/crm/fb-conversion", {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({ ...data, "event_time": Date.now() })
    })
  }

  const handleSubmit = (onSubmit) => {
    //check if bot filled out the form
    if (formValues["form-bot1"]) {
      return pNotification({
        type: "success",
        message: "Success",
        description:
          "Form has been submitted, form-bot",
      })
    } else {
      let url = typeof window !== "undefined" ? window.location.href : "null";
      if (!emailErr &&
        ((formValues.first_name !== "") || (formValues.firstName !== "")) &&
        validateEmail(formValues.email) &&
        (data?.confidence?.score > 0.250000 || error)) {
        window.globalEmail = formValues.email;
        window.globalPhone = `1${formValues.phone}`;
        dispatch(sendContactRequestAction(formValues));
        sendPixelInfo(formValues);
        typeof onSubmit === "function" && onSubmit();
        setFormValues(defaultFormValues);
        eventFormSubmission(
          url,
          `lead`,
          {
            phone: formValues.phone,
            email: formValues.email,
          }
        );
      } else {
        pNotification({
          type: "error",
          message: "Failed to submit!",
          description:
            "Please include Name, Email, and Phone!",
        })
      }
    }

  };

  return {
    formValues,
    loading,
    emailErr,
    handleChange,
    handleSubmit,
    setEmailErr,
  };
};

export default useForm;
